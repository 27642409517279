body {
  background-color: #FFFFFF;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Thunder";
  src: local("Thunder"),
   url("./fonts/Thunder-MediumLC.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "ThunderLight";
  src: local("ThunderLight"),
   url("./fonts/Thunder-LightLCItalic.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Didot";
  src: local("Didot"),
   url("./fonts/Didot-Regular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "IbarroRealNovaItalic";
  src: local("IbarroRealNova"),
   url("./fonts/IbarraRealNova-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "IbarroRealNova";
  src: local("IbarroRealNova"),
   url("./fonts/IbarraRealNova-Medium.ttf") format("truetype");
}

IbarraRealNova-Medium.ttf

@font-face {
  font-family: "Times";
  src: local("Times"),
   url("./fonts/times-new-roman.ttf") format("truetype");
  font-weight: bold;
}

@media (resolution: 2dppx) {
  .tab-icon-image {
    image-rendering: -moz-crisp-edges;
  }
}