.App {
  text-align: center;
}

.App h1 {
  margin-bottom: 18px;
  font-size: 4.5em !important;
  color: #ffffff !important;
  font-family: "Times";
  padding-top: 10px;
  padding-left: 10px;
  letter-spacing: 10px;
  font-weight: 200;
}

body {
  background-color: #101010;
}

@media (max-width: 770px) {
  .App h1 {
    padding-top: 32px;
    font-size: 3em !important;
    margin-bottom: 12px;
  }
}

@media (max-width: 400px) {
  .SyzygyLogo {
    top: 1.7em;
    height: 35px;
    width: 70%;
  }
}

@media (max-width: 600px) {
  .SyzygyLogo {
    top: 1.7em;
    height: 35px;
    width: 70%;
  }
}

.SyzygyLogo {
  text-align: center;
  height: 90px;
  width: 100%;
  bottom: 775px;
  position: absolute;
  background-image: url('../public/syzygy_logo.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  z-index: 300;
}

.VCLogo {
  text-align: center;
  height: 90px;
  width: 100%;
  bottom: 71px;
  position: absolute;
  background-image: url('../public/Backed_By_Polychain.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  z-index: 300;
  filter: invert(1)
}

.VCLogo p {
  position: absolute;
  top: 12.5px;
  font-size: 20px;
  font-style: italic;
  font-family: "Didot";
  letter-spacing: 1px;
  font-weight: 200 !important;
  margin-bottom: 5px !important; 
}

.sub-title {
  border: 1px solid black;
}

.sub-title h3 {
  font-family: "Didot";
  font-size: 40px !important;
  letter-spacing: 3px;
}

@media (max-width: 770px) {
  .VCLogo {
    height: 76px;
  }
}

.definition-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.underlinetext {
  border-bottom: 1px solid #ffffff;
  line-height: 1em;
  font-family: "IbarroRealNovaItalic";
}

.underlinetext a {
  text-decoration: none;
  color: #ffffff;
}

.subtext {
  font-family: "IbarroRealNova";
  position: absolute;
  width: 100%;
  top: 38.5%;
}

.subtext h2 {
  font-size: 2.5em;
  color: #ffffff;
}

@media (max-width: 770px) {
  .subtext {
    top: 41.7%;
  }

  .Syzygy-definition {
    width: 90%;
  }

  .subtext h2 {
    font-size: 1.5em;
  }
}